<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Change warehouse items category</div>
    </template>
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-float-label">
          <CustomDropdown id="warehouseItem_category_modal"
                          :class="{'p-invalid' : submitted && !selectedCategory}"
                          v-model="selectedCategory"
                          :clearSearchData="!visible"
                          :options="categories"
                          optionLabel="name"
                          :filter="true"
                          :showClear="true">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.name }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="warehouseItem_category_modal">{{ $t('Category') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedCategory">{{ $t('Required field') }}</small>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="changeWarehouseItemsCategory"/>
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";

export default {
  mixins: [ httpMixins ],
  emits: ['close', 'change-warehouse-items-category'],
  name: 'ChangeCategoryModal',
  props: {
    categories: Array,
    selectedItems: Array,
    visible: Boolean,
  },
  data() {
    return {
      dataIsSending: false,
      disableSaveButton: false,
      selectedCategory: null,
      submitted: false,
      settings
    }
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.disableSaveButton = false
        this.dataIsSending = false
        this.submitted = false
        this.selectedCategory = null
      }
    },
  },
  methods: {
    async changeWarehouseItemsCategory() {
      this.submitted = true
      if (!this.selectedCategory) {
        return false
      }
      this.disableSaveButton = true
      this.dataIsSending = true
      try {
        const obj = {
          warehouse_category_id: this.selectedCategory.id ?? null,
          ids: this.selectedItems
        }
        const { status } = await httpClient.post('warehouse/multiple-change-warehouse-items-category', obj)
        if (status === 200) {
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
          this.$emit('change-warehouse-items-category')
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
        this.disableSaveButton = false
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {width: '95%'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {width: '550px'}
      } else {
        return {width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>